#app-container {
  margin-bottom: 50px;
  padding-bottom: 50px;
}

/* Modal */
#modal-container {
  display: flex !important;
}

/* container */
.main {
  margin-top: 4em;
}

/* header */
#login-prompt:hover {
  cursor: pointer;
}

#header-logo {
  margin-right: 5px;
}

#user-info {
  background-color: rgba(0,0,0,.87);
}

/* documentation */

.doc-section-header {
  font-size: 1.5em;
  margin-bottom: 5px;
}

.doc-entry-title {
  font-size: 1.3em !important;
}

.doc-entry-content {
  padding: 0 0 1em 2.5em !important; 
}

.doc-entry-description {
  padding-bottom: .5em;
}

.doc-entry-list {
  margin: 0;
}

code {
  background-color: rgba(222,222,222,0.3);
  color: #7289DA;   /* discord blurple */
}

/* landing page */
#top-centered-item {
  left: 0;
  position: absolute;
  right: 0;
  top: 25%;
}

#landing-greeting {
  margin-top: 10px;
  margin-bottom: 0;
}

#landing-subtitle {
  margin-top: 8px;
}

#landing-button-container {
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-top: 24px;
  
  @media (max-width: 600px) {
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
}

/* Login Prompt */
.discord-theme {
  background-color: #7289DA !important;
}

/* CreateMove */
#move-form {
  margin-bottom: 60px;
}

.right {
  float: right;
}

.submit {
  margin-top: 1em !important;
}

.ui.form input[type=checkbox] {
  margin-right: 5px;
  vertical-align: middle !important;
}

/* ListMoves */
.move-list-link {
  text-decoration: underline;
}

.move-list-link:hover {
  text-decoration: underline;
}

/* ShowMoves */
#move-show-options {
  display: flex;
}         


/* Admin Options */
.admin-option {
  margin-left: 25px;
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  cursor: pointer;
}

.admin-option:hover {
  color: #4183c4
}

a {
  text-decoration: none;
  color: rgba(0,0,0,.87);
}

/* Footer */
.footer {
  bottom: 0;
  position: fixed !important;
  margin-top: -50px !important;
  width: 100%;
}
